<template>
  <b-container class="p-0 g-0">
    <!-- current question -->
    <b-row>
      <b-col md="12">
        <div class="mb-1">
          <h3 class="m-0">
            Today's Question
            <b-badge
              v-if="currentQuestion.id"
              pill
              variant="secondary"
              class="font-small"
            >
              #{{ currentQuestion.id }}
            </b-badge>
          </h3>
          <div class="pool-header">
            <h4
              v-if="currentPool.cohort_name"
              class="text-muted inline-block pr-1"
            >
              {{ currentPool.cohort_name }}
            </h4>
            <span
              v-if="currentQuestion.open_date"
              class="text-muted"
            >
              {{ currentPool.date_started || convertToLocalTime(currentQuestion.open_date) || todaysDate }}
            </span>
          </div>
        </div>
        <b-card class="mb-1 question-card">
          <div class="question-header">
            <!-- date/pool/prize -->
            <b-row v-if="currentPool.starting_count">
              <b-col cols="4">
                <span class="text-muted d-block">Entries</span>
                {{ currentPool.starting_count || currentQuestion.total_responses || '0' }}
              </b-col>
              <b-col
                cols="4"
                class="text-center"
              >
                <span class="text-muted d-block">Survivors</span>
                {{ currentPool.current_count || '0' }}
              </b-col>
              <b-col
                cols="4"
                class="text-right"
              >
                <span class="text-muted d-block">Prize</span>
                {{ getReadableDollarAmount(currentPool.prize_total_pennies) }}
              </b-col>
            </b-row>
          </div>

          <!-- question text -->
          <b-card-text class="mt-3 question-text text-center">
            {{ currentQuestion.text }}
          </b-card-text>

          <!-- question footer -->
          <div class="question-footer">
            <!-- login/register buttons -->
            <b-row class="my-1 button-row">
              <b-col
                cols="12"
                class="text-center"
              >
                <h3>SIGN IN TO PLAY</h3>
              </b-col>
              <b-col cols="6">
                <router-link
                  class="btn btn-primary btn-block text-uppercase"
                  to="/register"
                >
                  Join Now
                </router-link>
              </b-col>
              <b-col
                cols="6"
                class="col-6"
              >
                <router-link
                  class="btn btn-primary btn-block text-uppercase"
                  to="/login"
                >
                  Login
                </router-link>
              </b-col>
            </b-row>

            <!-- countdown to cutoff -->
            <b-row v-if="cutoffCountdownTime > 0">
              <b-col
                md="12"
                class="text-center my-1"
              >
                <countdown
                  :time="cutoffCountdownTime"
                  :emit-events="true"
                  @end="refreshForNewQuestion"
                >
                  <template slot-scope="props">
                    Closing in
                    {{ props.days ? `${props.days}d` : '' }}
                    {{ props.hours ? `${props.hours}h` : '' }}
                    {{ props.minutes ? `${props.minutes}m` : '' }}
                    {{ props.seconds ? `${props.seconds}s` : '' }}
                  </template>
                </countdown>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- promotional copy -->
    <b-row
      v-if="promotionalCopy"
      class="mt-2 mb-2"
    >
      <b-col md="12">
        <b-img
          :src="logoRound"
          center
          fluid
          width="300px"
          alt="Daily Survivor Pool"
          class="mb-1"
        />
        <h3>Welcome to Daily Survivor Pool!</h3>
        <b-card class="mt-1">
          <b-card-text class="m-0">
            {{ promotionalCopy }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <!-- next question countdown -->
    <b-row v-if="nextCountdownTime > 0">
      <b-col
        cols="12"
        class="my-2 text-center"
      >
        <h4>
          The next question is in
          <br>
          <countdown
            :time="nextCountdownTime"
            :emit-events="true"
            @end="refreshForNewQuestion"
          >
            <template slot-scope="props">
              {{ props.days ? `${props.days}d` : '' }}
              {{ props.hours ? `${props.hours}h` : '' }}
              {{ props.minutes ? `${props.minutes}m` : '' }}
              {{ props.seconds ? `${props.seconds}s` : '' }}
            </template>
          </countdown>
        </h4>
      </b-col>
    </b-row>

    <!-- ongoing pools -->
    <b-row class="my-2">
      <b-col md="12">
        <h3>All Ongoing Pools</h3>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col cols="12">
              <!-- pool boxes -->
              <div
                v-for="pool in poolsOngoing"
                :key="pool.id"
              >
                <h3>Play for free!</h3>
                <b-row class="pool-container">
                  <b-col xs="6">
                    <h4>{{ pool.cohort_name }}</h4>
                    <p><span class="pool-var">Started:</span> {{ pool.date_started }}</p>
                    <p><span class="pool-var">Total Entries:</span> {{ pool.starting_count }}</p>
                  </b-col>
                  <b-col xs="6">
                    <h4>&nbsp;</h4>
                    <p><span class="pool-var">Prize:</span> {{ getReadableDollarAmount(pool.prize_total_pennies) }}</p>
                    <p><span class="pool-var">Survivors:</span> {{ pool.current_count }}</p>
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="poolsOngoing.length < 1"
              >
                Answer a question to join an active pool!
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BBadge, BCard, BCardText, BImg,
} from 'bootstrap-vue'
import moment from 'moment'

import { isUserLoggedIn } from '@/auth/utils'
import IndexBackground from '@/assets/images/backgrounds/index.png'
import LogoRound from '@/assets/images/logo/logo-round.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardText,
    BImg,
  },
  data() {
    return {
      logoRound: LogoRound,
      poolsTableFields: [
        { key: 'date_started', label: 'Date Started' },
        { key: 'cohort_name', label: 'Name' },
        { key: 'starting_count', label: 'Entries' },
        { key: 'current_count', label: 'Survivors' },
        { key: 'prize_total_pennies', label: 'prize' },
        // { key: 'actions', label: '' },
      ],
      todaysDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    }
  },
  computed: {
    promotionalCopy() {
      return this.$store.getters['misc/getPromotionalCopy']
    },
    currentQuestion() {
      return this.$store.getters['questions/getPublicQuestion']
    },
    poolsOngoing() {
      return this.$store.getters['pools/getAllPublicPools']
    },
    currentPool() {
      return this.poolsOngoing[0] || {}
    },
    cutoffCountdownTime() {
      const currentDate = new Date()
      const cutoffDate = new Date(this.convertToLocalTime(this.currentQuestion.cutoff_date))
      const countdown = cutoffDate.getTime() - currentDate.getTime()
      return countdown
    },
    nextCountdownTime() {
      const currentDate = new Date()
      const nextDate = new Date(this.convertToLocalTime(this.currentQuestion.next_question_open_date))
      const countdown = nextDate.getTime() - currentDate.getTime()
      return countdown
    },
  },
  created() {
    document.body.style.backgroundImage = `url(${IndexBackground})`
  },
  mounted() {
    if (isUserLoggedIn()) {
      this.$router.push('/home')
    }

    this.$store.dispatch('misc/fetchPromotionalCopy')
    this.refreshForNewQuestion()
  },
  methods: {
    /* DATA RETRIEVAL */

    refreshForNewQuestion() {
      this.$store.dispatch('questions/fetchPublicQuestion')
      this.$store.dispatch('pools/fetchAllPublicPools')
    },

    /* MISCELLANEOUS */

    getReadableDollarAmount(penniesStr) {
      let formattedDollarAmount = '$0'
      if (penniesStr > 0) {
        formattedDollarAmount = (penniesStr / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) // TODO get local currency
      }
      return formattedDollarAmount
    },
    convertToLocalDate(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD')
      return convertedDate
    },
    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
  },
}
</script>

<style>
.inline-block {
  display: inline-block;
}
</style>
